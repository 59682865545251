import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import React, {useEffect, useRef, useState} from "react";
import Modal from "react-bootstrap/Modal";
import NotificationAlert from "react-notification-alert";
import {Button, Card, CardBody, CardHeader, Input, InputGroup, InputGroupAddon, InputGroupText} from "reactstrap";
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import {axios_bot, changeFields, checkErrorMessage, makeId, notify} from "../../../tools/tools";
import ManageData from "./manageData";

function ChatData() {

    const chatDataAlert = useRef()
    const isMounted = useRef(false);
    const [open, setOpen] = useState(false);
    const [edit, setEdit] = useState(false);
    const [deleteReq, setDeleteReq] = useState(false);
    const [openPatterns, setOpenPatterns] = useState(false);
    const [openResponses, setOpenResponses] = useState(false);
    const [index, setIndex] = useState(null);
    const [tags, setTags] = useState([]);
    const [title, setTitle] = useState('');
    const [tagTmp, setTagTmp] = useState('');
    const [titleTag, setTitleTag] = useState('');

    const handleClick = async (row) => {
        setIndex(row)
        setOpen(!open);
    };

    const handleEdit = async (row) => {
        setIndex(row)
        setEdit(true)
    };

    const getTags = () => {
        let _axios = axios_bot();
        _axios.get('/bot/admin/tags').then((resp) => {
            setTags(resp.data)
        }).catch((error) => {
            let errorMessage = checkErrorMessage(error);
            notify('danger', errorMessage, chatDataAlert)
        })
    }

    const upTitle = () => {
        let _axios = axios_bot();
        _axios.put('/bot/admin/update/intent/title', {'title': titleTag, 'tag': tags[index]['tag']}).then(async (resp) => {
            setEdit(false);
            setTitleTag('');
            setIndex(null);
            getTags()
            notify('success', "Titre mis à jour", chatDataAlert)
        }).catch((error) => {
            let errorMessage = checkErrorMessage(error);
            notify('danger', errorMessage, chatDataAlert)
        })
    }

    const addIntent = () => {
        let _axios = axios_bot();
        _axios.post('/bot/admin/create/intent', {'title': title, 'tag': 'admin_' + makeId(5)}).then(() => {
            getTags()
            notify('success', "Un nouveau theme a été ajoutée à la liste", chatDataAlert)
        }).catch((error) => {
            let errorMessage = checkErrorMessage(error);
            notify('danger', errorMessage, chatDataAlert)
        })
    }

    const deleteIntent = () => {
        let _axios = axios_bot();
        _axios.delete('/bot/admin/delete/intent/' + tagTmp).then(() => {
            getTags()
            notify('success', "Thème supprimé avec succès", chatDataAlert)
        }).catch((error) => {
            let errorMessage = checkErrorMessage(error);
            notify('danger', errorMessage, chatDataAlert)
        })
    }

    useEffect(() => {

        getTags()

        return () => {
            isMounted.current = true
        };
    }, []);

    return (
        <Card className="card-stats mb-0" style={{height: '85vh'}}>
            <NotificationAlert ref={chatDataAlert}/>

            <Modal size="sm" show={deleteReq} aria-labelledby="example-modal-sizes-title-sm">
                <Modal.Body>
                    Êtes vous sur de vouloir supprimer ?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" color="danger" onClick={() => setDeleteReq(false)}>
                        Non
                    </Button>
                    <Button variant="primary" color="success" onClick={() => {
                        setDeleteReq(false)
                        deleteIntent()
                    }}>Oui</Button>
                </Modal.Footer>
            </Modal>

            <CardHeader>
                <p className="text-center text-uppercase">
                    Connaissances du Robot&nbsp;
                </p>
                <hr/>
            </CardHeader>

            <CardBody>
                <InputGroup className="no-border">
                    <Input placeholder={"Créer un nouveau thème ..."}
                           value={title}
                           onChange={(e) => changeFields(setTitle, e)}/>
                    <InputGroupAddon addonType="append" onClick={() => title.length && addIntent()}>
                        <InputGroupText>
                            <i className="nc-icon nc-simple-add text-success ml-2"/>
                        </InputGroupText>
                    </InputGroupAddon>
                </InputGroup>
                <List
                    className="overflow-auto"
                    sx={{height: '70vh', bgcolor: 'background.paper'}}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                >
                    {tags?.map((value, row) =>
                        <div key={row + '_tags'}>

                            <ListItemButton className="ml-3 pl-2 text-truncate" style={{width: '90%'}}>
                                <ListItemIcon>
                                    <ModeEditOutlinedIcon
                                        sx={{color: "#51cbce!important"}}
                                        onClick={() => handleEdit(row)}/>
                                </ListItemIcon>
                                {!edit
                                    ? <ListItemText primary={value['title']} onClick={() => handleEdit(row)}/>
                                    : edit && index === row
                                        ? <InputGroup className="no-border pt-2 mr-2">
                                            <Input placeholder={value['title']}
                                                   value={titleTag}
                                                   onChange={(e) => changeFields(setTitleTag, e)}/>
                                            <InputGroupAddon addonType="append">
                                                <InputGroupText onClick={() => {
                                                    setEdit(false)
                                                    setIndex(null)
                                                    setTitleTag('')
                                                }}>
                                                    <i className="nc-icon nc-simple-remove text-danger ml-2"/>
                                                </InputGroupText>
                                                <InputGroupText onClick={() => titleTag.length && upTitle()}>
                                                    <i className="nc-icon nc-send text-success ml-2"/>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                        </InputGroup>
                                        : <ListItemText primary={value['title']} onClick={() => handleEdit(row)}/>}
                                <DeleteSweepIcon sx={{color: "#ED1C24!important"}} onClick={async () => {
                                    setDeleteReq(true)
                                    setTagTmp(value.tag)
                                }}/>
                                {open && index === row
                                    ? <ExpandLess onClick={() => handleClick(row)}/>
                                    : <ExpandMore onClick={() => handleClick(row)}/>}
                            </ListItemButton>

                            <Collapse in={open && index === row} timeout="auto" unmountOnExit>

                                <List component="div" disablePadding>
                                    <ListItemButton className="ml-4 pl-2 pr-2" style={{width: '85%'}}>

                                        <ListItemIcon>
                                            <PsychologyAltIcon sx={{color: "#6bd098!important"}}/>
                                        </ListItemIcon>
                                        <ListItemText primary="Les patterns"/>
                                        {openPatterns && index === row
                                            ? <PlaylistRemoveIcon onClick={() => setOpenPatterns(!openPatterns)}/>
                                            : <LowPriorityIcon onClick={() => setOpenPatterns(!openPatterns)}/>}

                                    </ListItemButton>
                                    <Collapse in={openPatterns && index === row} timeout="auto" unmountOnExit>

                                        <ManageData tag={value.tag} prefix="patterns"/>

                                    </Collapse>
                                </List>

                                <List component="div" disablePadding>
                                    <ListItemButton className="ml-4 pl-2 pr-2" style={{width: '85%'}}>

                                        <ListItemIcon>
                                            <QuestionAnswerIcon sx={{color: "#fce883!important"}}/>
                                        </ListItemIcon>
                                        <ListItemText primary="Les réponses"/>
                                        {openResponses && index === row
                                            ? <PlaylistRemoveIcon onClick={() => setOpenResponses(!openResponses)}/>
                                            : <LowPriorityIcon onClick={() => setOpenResponses(!openResponses)}/>}

                                    </ListItemButton>
                                    <Collapse in={openResponses && index === row} timeout="auto" unmountOnExit>

                                        <ManageData tag={value.tag} prefix="responses"/>

                                    </Collapse>
                                </List>
                            </Collapse>
                        </div>
                    )}
                </List>
            </CardBody>
        </Card>
    );
}

export default ChatData;
