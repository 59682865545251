import axios from "axios";
import React, {useCallback, useEffect, useState} from "react";
import {Card, CardBody, CardFooter, Col, Row, Spinner} from "reactstrap";

function ArtistsRanking() {
    const [loading, setLoading] = useState(true);
    const [ranking, setRanking] = useState([]);
    const [currentYear, setCurrentYear] = useState(1900 + new Date().getYear());

    const changeYear = useCallback(
        async (nbr) => {
            const newYear = currentYear + nbr;
            setCurrentYear(newYear);
            await checkArtistRanking(newYear);
        },

        /* eslint-disable-next-line react-hooks/exhaustive-deps */
        [currentYear]
    );

    const checkArtistRanking = useCallback(async (year) => {
        setLoading(true);
        const newYear = year || currentYear;
        try {
            const response = await axios.get(
                `/admin/reservation/artist_ranking/${newYear}`
            );
            setRanking(response.data || []);
        } catch (error) {
            console.error("Erreur lors de la récupération du classement :", error);
        } finally {
            setLoading(false);
        }
    }, [currentYear]);

    useEffect(() => {
        checkArtistRanking().then(r => null);

        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [checkArtistRanking]);

    return (
        <Card className="user-stats-card" style={{height: '100%'}}>
            <CardBody>
                <p className="text-center text-uppercase title">
                    Classement des artistes ({currentYear})
                </p>
                <hr className="custom-divider"/>
                <div className="custom-table">
                    <Row className="p-2">
                        <Col md="4" className="text-left">
                            <p className="stat-label">Rang</p>
                        </Col>
                        <Col md="4" className="text-center">
                            <p className="stat-label">Nom</p>
                        </Col>
                        <Col md="4" className="text-right">
                            <p className="stat-label">Nb.R</p>
                        </Col>
                    </Row>
                    {!ranking.length ? (
                        <p className="text-center">Aucun artiste à afficher</p>
                    ) : (
                        ranking.map((value, index) => (
                            <Row key={index} className="p-2">
                                <Col md="2" className="text-left text-success">
                                    {index + 1}
                                </Col>
                                <Col md="8" className="text-center font-weight-bold">
                                    {value?.artist?.name}
                                </Col>
                                <Col md="2" className="text-right font-weight-bold text-danger">
                                    {value?.service_realized}
                                </Col>
                            </Row>
                        ))
                    )}
                </div>
            </CardBody>
            <CardFooter className="footer d-flex justify-content-between">
                <div style={{ flex: 1 }} className="text-left">
                    <button
                        className="btn-refresh"
                        onClick={() => changeYear(-1)}
                        title="Année précédente"
                    >
                        <i className="nc-icon nc-minimal-left"></i>
                    </button>
                </div>

                <div className="refresh" style={{ flex: 1, textAlign: "center" }}>
                    {loading ? (
                        <Spinner size="sm" color="light" />
                    ) : (
                        <button
                            className="btn-refresh"
                            onClick={() => checkArtistRanking()}
                            title="Actualiser le classement"
                        >
                            <i className="fas fa-sync-alt"></i>
                        </button>
                    )}
                </div>

                <div style={{ flex: 1, textAlign: "right" }}>
                    <button
                        className="btn-refresh"
                        onClick={() => changeYear(1)}
                        title="Année suivante"
                    >
                        <i className="nc-icon nc-minimal-right"></i>
                    </button>
                </div>
            </CardFooter>

        </Card>
    );
}

export default ArtistsRanking;
