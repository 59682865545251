import Typography from "@mui/material/Typography";
import React, {useState} from "react";
import dayjs from "dayjs";
import axios from "axios";
import {Modal, ModalHeader, ModalBody, ModalFooter, Button, Row} from "reactstrap";
import {Checkbox, TextField, FormControlLabel, FormHelperText} from "@mui/material";
import {deleteInObject, detectFileType, notify} from "../../tools/tools";


function CertificationModal({closeModal, selectedCertification, certificationAlert, updateCertification}) {

    const [modalOpen, setModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [adminMessage, setAdminMessage] = useState(selectedCertification?.admin_message)
    const [validation, setValidation] = useState({
        name: false,
        surname: false,
        dob: false, // date of birth
    });

    // Fonction pour envoyer les données à l'API
    const sendDataToAPI = async (action) => {
        if (!adminMessage && action === "update") {
            notify(
                'danger',
                "Veuillez remplir le message pour indiquer le problème.",
                certificationAlert
            )
            return;
        }

        setLoading(true);

        const missingFields = action === "approve" ? [] : Object.keys(validation).filter((key) => !validation[key]);

        const payload = deleteInObject({
            ...selectedCertification,
            ...{
                admin_message: action === "approve" ? '' : adminMessage,
                missing_fields: missingFields,
            },
        }, ['info_to_compare', 'artist_name'])

        try {
            const response = await axios.put("/admin/certifications/update/" + selectedCertification.id, payload);

            notify(
                'success',
                `Action '${action}' effectuée avec succès.`,
                certificationAlert
            )

            updateCertification(response.data);

            closeModal();
        } catch (error) {
            notify(
                'danger',
                "Une erreur est survenue lors de la soumission.",
                certificationAlert
            )
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const handleCheckboxChange = (field) => {
        setValidation((prev) => ({
            ...prev,
            [field]: !prev[field],
        }));
    }

    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };

    const openImageInModal = (url) => {
        setSelectedImage(url);
        toggleModal();
    };


    return (
        <Modal isOpen toggle={closeModal} size="lg" className="modal-content custom-modal">

            <Modal isOpen={modalOpen} toggle={toggleModal} size="lg" centered>
                <ModalBody>
                    {selectedImage && (
                        detectFileType(selectedImage) === "image" ? (
                            <img src={selectedImage} alt="Agrandissement" className="img-fluid"/>
                        ) : (
                            <iframe
                                src={selectedImage}
                                title="Aperçu du document"
                                style={{width: "100%", height: "500px", border: "none"}}
                            />
                        )
                    )}
                </ModalBody>
            </Modal>

            <ModalHeader toggle={closeModal}>
                Détails de la Certification pour {selectedCertification.artist_name}
            </ModalHeader>

            <ModalBody style={{color: "white"}}>
                <Row>
                    {/* Colonne gauche : Documents */}
                    <div className="col-md-4">
                        <h5>Documents</h5>
                        <div className="documents-tab">
                            <div className="documents-grid">
                                {selectedCertification.document_urls.map((url, index) => {
                                    const fileType = detectFileType(url);

                                    return fileType === "image" ? (
                                        <img
                                            key={index}
                                            src={url}
                                            alt={`Document ${index + 1}`}
                                            className="document-preview img-fluid mb-3"
                                            onClick={() => openImageInModal(url)}
                                        />
                                    ) : (
                                        <Typography
                                            variant="body2"
                                            onClick={() => openImageInModal(url)}
                                            sx={{color: "#afa9a9", cursor: "pointer !important"}}
                                        >
                                            Appuyer pour voir le document pdf
                                        </Typography>
                                    )
                                })}
                            </div>
                        </div>
                    </div>

                    {/* Colonne droite : Message admin */}
                    <div className="col-md-8">
                        <h5>Validation des informations</h5>
                        <FormHelperText className="mb-3" style={{color: "lightgray"}}>
                            Si un élément est correct, cochez la case correspondante.
                        </FormHelperText>

                        <div className="validation-item">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={validation.name}
                                        onChange={() => handleCheckboxChange("name")}
                                        sx={{
                                            color: "white !important",
                                            "&.Mui-checked": { color: "white !important" },
                                        }}
                                    />
                                }
                                label={
                                    <span style={{color: "white"}}>
                                        Nom : {selectedCertification?.info_to_compare.lastname || "Non renseigné"}
                                    </span>
                                }
                            />
                        </div>

                        <div className="validation-item">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={validation.surname}
                                        onChange={() => handleCheckboxChange("surname")}
                                        sx={{
                                            color: "white !important",
                                            "&.Mui-checked": { color: "white !important" },
                                        }}
                                    />
                                }
                                label={
                                    <span style={{color: "white"}}>
                                        Prénom : {selectedCertification?.info_to_compare.name || "Non renseigné"}
                                    </span>
                                }
                            />
                        </div>

                        <div className="validation-item">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={validation.dob}
                                        onChange={() => handleCheckboxChange("dob")}
                                        sx={{
                                            color: "white !important",
                                            "&.Mui-checked": { color: "white !important" },
                                        }}
                                    />
                                }
                                label={
                                    <span style={{color: "white"}}>
                                        Date de naissance : {dayjs(selectedCertification?.info_to_compare.birth).format("DD-MM-YYYY") || "Non renseignée"}
                                    </span>
                                }
                            />
                        </div>

                        <h5 className="mt-4">Message</h5>
                        <FormHelperText className="mb-3" style={{color: "lightgray"}}>
                            Expliquez précisément à l'artiste ce qui manque ou doit être corrigé pour que la
                            certification
                            avance rapidement.
                        </FormHelperText>
                        <TextField
                            fullWidth
                            rows={4}
                            multiline
                            placeholder="Ajoutez un message en cas de problème"
                            value={adminMessage}
                            onChange={(e) => setAdminMessage(e.target.value)}
                            variant="outlined"
                            InputProps={{
                                style: {
                                    color: "white", // Texte en blanc
                                    borderRadius: "15px", // Bords arrondis
                                },
                            }}
                            InputLabelProps={{
                                style: {
                                    color: "white", // Couleur du label (placeholder)
                                },
                            }}
                            sx={{
                                backgroundColor: "rgb(116,115,113)", // Couleur du champ de texte
                                borderRadius: "15px", // Arrondir les bords
                                "& .MuiOutlinedInput-root": {
                                    "& fieldset": {
                                        borderColor: "white", // Couleur de la bordure
                                    },
                                    "&:hover fieldset": {
                                        borderColor: "white", // Couleur de la bordure au survol
                                    },
                                    "&.Mui-focused fieldset": {
                                        borderColor: "white", // Couleur de la bordure quand focus
                                    },
                                },
                            }}
                        />
                    </div>
                </Row>
            </ModalBody>

            <ModalFooter>
                <Button
                    color="success"
                    disabled={loading}
                    onClick={() => sendDataToAPI("approve")}
                >
                    {loading ? "Chargement..." : "Approuver"}
                </Button>
                <Button
                    color="warning"
                    disabled={loading}
                    onClick={() => sendDataToAPI("update")}
                >
                    {loading ? "Chargement..." : "Demande de mis à jour"}
                </Button>
            </ModalFooter>
        </Modal>
    );
}

export default CertificationModal;
