import axios from "axios";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {Row, Col, Spinner} from "reactstrap";
import NotificationAlert from "react-notification-alert";
import Payment from "../../components/Main/payment";
import PaymentList from "./paymentList";

function PaymentHistory() {

    const notificationAlert = useRef()
    const [loading, setLoading] = useState(true)
    const [payments, setPayments] = useState([]);
    const [paymentToShow, setPaymentToShow] = useState({});

    const checkLastPayments = useCallback(async () => {
        setLoading(true)
        await axios.get('/admin/payments/last').then((response) => {
            let data = response.data;
            if (data.length)
                setPaymentToShow(data[0])
            else setPaymentToShow({})
            setPayments(data)
            setLoading(false)
        })
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [])

    useEffect(() => {
        checkLastPayments().then(() => null);
    }, [checkLastPayments]);

    useEffect(() => {

    }, []);

    return (
        <div className="content">
            <NotificationAlert ref={notificationAlert}/>
            {loading ? (
                <div className="text-center mt-5">
                    <Spinner color="primary"/>
                    <p>Chargement des payments...</p>
                </div>
            ) : (
                <Row>
                    <Col md="4">
                        <PaymentList
                            payments={payments}
                            setPayments={setPayments}
                            paymentToShow={paymentToShow}
                            setPaymentToShow={setPaymentToShow}
                            checkLastPayments={checkLastPayments}/>
                    </Col>
                    <Col md="8">
                        {Object.keys(paymentToShow).length !== 0
                            ? <Payment payment={paymentToShow}/>
                            : <p className="text-center">Pas de payment a afficher</p>
                        }
                    </Col>
                </Row>
            )}
        </div>
    );
}

export default PaymentHistory;
