import axios from "axios";
import React, {useCallback, useEffect, useState} from "react";
import {Card, CardBody, CardFooter, Col, Row, Spinner} from "reactstrap";

function IncomeOfTheMonth() {
    const [loading, setLoading] = useState(false);
    const [potentialFee, setPotentialFee] = useState(0);
    const [reservationPaid, setReservationPaid] = useState(0);
    const [reservationPending, setReservationPending] = useState(0);
    const [reservationAccepted, setReservationAccepted] = useState(0);
    const [reservationDeclined, setReservationDeclined] = useState(0);
    const [reservationRealized, setReservationRealized] = useState(0);

    const checkMonthFee = useCallback(async () => {
        setLoading(true);
        try {
            const response = await axios.get('/admin/reservation/income_month');
            const data = response.data;
            setPotentialFee(data['potential_fee']);
            setReservationPaid(data['reservation_paid']);
            setReservationPending(data['reservation_pending']);
            setReservationAccepted(data['reservation_accepted']);
            setReservationDeclined(data['reservation_declined']);
            setReservationRealized(data['reservation_realized_number_month']);
        } catch (error) {
            console.error("Failed to fetch income stats:", error);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        checkMonthFee();
    }, [checkMonthFee]);

    return (
        <Card className="user-stats-card">
            <CardBody>
                <p className="text-center text-uppercase title">Réservation du mois</p>
                <hr className="custom-divider"/>
                <Row>
                    <Col md="6" xs="5">
                        <div className="stat-row">
                            <em className="stat-label">Payé</em>
                            <p className="stat-value text-warning">{reservationPaid}</p>
                        </div>
                        <div className="stat-row">
                            <em className="stat-label">Réalisé</em>
                            <p className="stat-value text-success">{reservationRealized}</p>
                        </div>
                        <div className="stat-row">
                            <em className="stat-label">Accepté</em>
                            <p className="stat-value">{reservationAccepted}</p>
                        </div>
                        <div className="stat-row">
                            <em className="stat-label">En attente</em>
                            <p className="stat-value text-warning">{reservationPending}</p>
                        </div>
                        <div className="stat-row">
                            <em className="stat-label">Décliné</em>
                            <p className="stat-value text-danger">{reservationDeclined}</p>
                        </div>
                    </Col>
                    <Col md="6" xs="7" className="total-section">
                        <div>
                            <p className="card-category">Potentiel</p>
                            <h5 className="total-value">{potentialFee || 0}€</h5>
                        </div>
                    </Col>
                </Row>
            </CardBody>
            <CardFooter className="footer">
                <div className="refresh">
                    {loading ? (
                        <Spinner size="sm" color="light"/>
                    ) : (
                        <button
                            className="btn-refresh"
                            onClick={checkMonthFee}
                            title="Actualiser les statistiques"
                        >
                            <i className="fas fa-sync-alt"></i>
                        </button>
                    )}
                </div>
            </CardFooter>
        </Card>
    );
}

export default IncomeOfTheMonth;
