import React, {useState} from "react";
import axios from "axios";
import {Input, Table} from "reactstrap";


function UsersList({users, setUserToShow, userToShow, setUsers, checkLastUserSignIn}) {

    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");

    const searchUser = async (e) => {
        let str = e.target.value;
        setSearchTerm(str);
        if (str) {
            setLoading(true);
            await axios.get("/admin/users/search_profile/" + str).then((response) => {
                let data = response.data;
                if (data.length) setUserToShow(data[0]);
                else setUserToShow({});
                setUsers(data);
                setLoading(false);
            });
        } else {
            await checkLastUserSignIn();
        }
    };

    const filteredUsers = users.filter(
        (user) =>
            user.my_profile?.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            user.role?.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="users-list">
            {/* Barre de recherche */}
            <Input
                type="text"
                value={searchTerm}
                placeholder="Rechercher l'utilisateur, email, nom, prénom, ..."
                onChange={(e) => searchUser(e)}
                className="custom-input mb-3"
            />

            {/* Tableau des utilisateurs */}
            <Table hover responsive className="custom-table">
                <thead>
                <tr>
                    <th>Email</th>
                    <th>Type</th>
                </tr>
                </thead>
                <tbody>
                {filteredUsers.length === 0 ? (
                    <tr>
                        <td colSpan="3" className="text-center">
                            Aucun utilisateur trouvé
                        </td>
                    </tr>
                ) : (
                    filteredUsers.map((user, index) => (
                        <tr
                            key={index}
                            className={`table-row ${
                                userToShow?.user_id === user.user_id ? "selected-row" : ""
                            }`}
                            onClick={() => setUserToShow(user)}
                        >
                            <td>{user.my_profile?.email}</td>
                            <td>{user.role}</td>
                        </tr>
                    ))
                )}
                </tbody>
            </Table>
        </div>
    );
}

export default UsersList;
