import axios from "axios";
import React, {useCallback, useEffect, useRef, useState} from "react";
import Modal from 'react-bootstrap/Modal';
import {useSelector} from "react-redux";
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    Col, FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Row,
    Spinner,
} from "reactstrap";
import logo from "../../assets/img/logo.png";
import AdminMembers from "../../profileData/adminMembers";
import NotificationAlert from "react-notification-alert";
import FeeDiagrams from "../../profileData/feeDiagrams";
import {notify} from "../../tools/tools";
import p_cover from '../../assets/img/damir-bosnjak.jpg';

function User() {

    const notificationAlert = useRef()
    const [right, setRight] = useState(1);
    const [show, setShow] = useState(false);
    const [profile, setProfile] = useState({});
    const [tShow, setTShow] = useState(false);
    const [smShow, setSmShow] = useState(false);
    const [tarShow, setTarShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [country, setCountry] = useState('');
    const [countryFounds, setCountryFounds] = useState([]);
    const [userToAdmin, setUserToAdmin] = useState({});
    const [searchResult, setSearchResult] = useState([]);
    const countries = useSelector(state => state.global.countries);

    const checkAdminProfile = useCallback(async () => {
        await axios.get('profiles/my_profile').then((response) => {
            let data = response.data;
            setRight(data['right'])
            setProfile(data['my_profile'])
        })
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [])

    const refreshTest = async () => {
      if (country?.name) {
          setLoading(true)
          await axios.post('/admin/services/generate_data_test/' + country.name.toLowerCase()).then(() => {
              setTShow(false)
              setLoading(false)
          }).catch(() => {
              setLoading(false)
          })
      }
    }

    const searchUser = async (e) => {
        let str = e.target.value;
        if (str) {
            setLoading(true)
            await axios.get('/admin/users/search_auditor/' + str).then((response) => {
                let data = response.data;
                setSearchResult(data)
                setLoading(false)
            })
        }
    }

    const searchCountry = async (e) => {
        setLoading(true)
        let str = e.target.value;
        if (str?.length >= 3) {
            let tmp = countries.filter((c) =>
                c.name.toLowerCase().includes(str.toLowerCase()) ||
                c.nativeName.toLowerCase().includes(str.toLowerCase()))
            setCountryFounds(tmp)
            setLoading(false)
        } else {
            setLoading(false)
        }
    }

    const addCountry = (_country) => {
        setLoading(true)
        setCountry(_country)
        setTarShow(false)
        setLoading(false)
    }

    const AddNewAdmin = () => {
        setLoading(true)
        axios.put("/admin/users/new_admin/" + userToAdmin.id.toString()).then(async response => {
            setShow(false);
            setSmShow(false);
            setLoading(false)
            notify('success', "Vous pouvez actualiser la liste des admins", notificationAlert)
        })
    }

    const AlertNewAdmin = (value) => {
        setUserToAdmin(value);
        setSmShow(true)
    }

    useEffect(() => {
        checkAdminProfile().then(() => null);
    }, [checkAdminProfile]);

    useEffect(() => {

    }, []);

    return (
        <div className="content">
            <NotificationAlert ref={notificationAlert} />

            <Modal show={tShow} backdrop="static">
                <Modal.Header>
                    <h5 className="col text-center">
                        Actualiser les données tests&nbsp;
                    </h5>
                </Modal.Header>
                <Modal.Body>
                    <div className="text-center overflow-auto mt-2 mb-2">
                        <Row>
                            <Col md="12">
                                <FormGroup>
                                    <label>Assigner les test</label>
                                    <Button style={{marginTop: '-1px', marginBottom: 1}}
                                            className={country?.name ? "col btn-info" : "col secondary"}
                                            onClick={() => setTarShow(true)}>
                                        {country?.name ? country?.name : "Pour quel pays seront les tests"}
                                    </Button>
                                </FormGroup>
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="danger" disabled={loading} onClick={() => setTShow(false)}>
                        Fermer
                    </Button>
                    <Button className="success" disabled={loading} onClick={() => refreshTest()}>Valider</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={tarShow} size="m" className="mt-2">
                <Modal.Header>
                    <InputGroup className="no-border">
                        <Input placeholder="Chercher le pays"
                               onChange={(e) => searchCountry(e)}/>
                    </InputGroup>
                </Modal.Header>
                <Modal.Body>
                    <div className="text-center overflow-auto" style={{height: 200}}>
                        {loading
                            ? <Spinner animation="grow" size="m" className="mr-2"/>
                            : !countryFounds.length
                                ? <p>Pas de resultat a afficher</p>
                                : countryFounds.map((value, index) =>
                                    <Row key={index + 'search'}>
                                        <Col className="ml-auto mr-auto" lg="7" md="6" xs="6">
                                            <p>{value.name}</p>
                                        </Col>
                                        <Col className="mr-auto cursor-pointer" lg="2">
                                            <i className="nc-icon nc-simple-add text-success"
                                               onClick={() => addCountry(value)}/>
                                        </Col>
                                    </Row>
                                )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn-danger" onClick={() => setTarShow(false)}>
                        Fermer
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={show} backdrop="static">
                <Modal.Header>
                    <InputGroup className="no-border">
                        <Input placeholder="Chercher le nouveau admin" style={{width: 430}}
                               onChange={(e) => searchUser(e)}/>
                        <InputGroupAddon addonType="append">
                            <InputGroupText>
                                <i className="nc-icon nc-zoom-split ml-2"/>
                            </InputGroupText>
                        </InputGroupAddon>
                    </InputGroup>

                    <Modal size="sm" show={smShow} aria-labelledby="example-modal-sizes-title-sm">
                        <Modal.Body>
                            Ajouter {userToAdmin.name} comme admin ?
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" color="danger" onClick={() => setSmShow(false)}>
                                Non
                            </Button>
                            <Button variant="primary" color="success" onClick={() => AddNewAdmin()}>Oui</Button>
                        </Modal.Footer>
                    </Modal>
                </Modal.Header>
                <Modal.Body>
                    <div className="text-center overflow-auto" style={{height: 400}}>
                        {loading
                            ? <Spinner animation="grow" size="m" className="mr-2"/>
                            : !searchResult.length
                                ? <p>Pas de resultat a afficher</p>
                                : searchResult.map((value, index) =>
                                    <Row key={index}>
                                        <Col className="ml-auto" lg="3" md="5" xs="6">
                                            <p>
                                                {value.name.length <= 9 ? value.name : value.name.substring(0, 9) + '...'}
                                            </p>
                                        </Col>
                                        <Col className="ml-auto mr-auto" lg="7" md="6" xs="6">
                                            <p>{value.email}</p>
                                        </Col>
                                        <Col className="mr-auto" lg="2">
                                            <i className="nc-icon nc-simple-add cursor-pointer text-success"
                                               onClick={() => AlertNewAdmin(value)}/>
                                        </Col>
                                    </Row>
                                )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="danger" onClick={() => setShow(false)}>
                        Fermer
                    </Button>
                    <Button className="success">Valider</Button>
                </Modal.Footer>
            </Modal>

            <Row>
                <Col md="4">
                    <Card className="card-user">
                        <div className="image">
                            <img
                                alt="..."
                                src={profile.cover_photo ? profile.cover_photo : p_cover}
                            />
                        </div>
                        <CardBody>
                            <div className="author">
                                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                    <img
                                        alt="..."
                                        className="avatar border-gray"
                                        src={profile.photo ? profile.photo : logo}
                                    />
                                    <h5 className="title">{profile.name}</h5>
                                </a>
                                <p className="description">{profile.email}</p>
                                <AdminMembers userConnectedRight={right}/>
                            </div>
                        </CardBody>
                        <CardFooter>
                            <hr />
                            <div className="button-container">
                                <Row>
                                    <Col className="ml-auto" lg="4" md="6" xs="6">
                                        <Button
                                            color="primary"
                                            disabled={right === 1}
                                            onClick={() => setShow(true)}
                                            className="d-flex align-items-center justify-content-center"
                                        >
                                            <i className="nc-icon nc-simple-add text-white mr-2" />
                                            Admin
                                        </Button>
                                    </Col>
                                    <Col className="ml-auto mr-auto" lg="8" md="6" xs="6">
                                        <Button
                                            color="secondary"
                                            disabled={right === 1}
                                            onClick={() => setTShow(true)}
                                            className="d-flex align-items-center justify-content-center"
                                        >
                                            <i className="fas fa-sync-alt text-white mr-2" />
                                            Actualiser les tests
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        </CardFooter>
                    </Card>
                </Col>
                <Col md="8">
                    <FeeDiagrams/>
                </Col>
            </Row>
        </div>
    );
}

export default User;
