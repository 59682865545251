import React, {useState} from "react";
import {Input, Table} from "reactstrap";
import EditIcon from "@mui/icons-material/Edit";
import dayjs from "dayjs";
import VerifiedIcon from '@mui/icons-material/Verified';

function CertificationList({certifications, selectedCertification, onSelectCertification, tOpenModal}) {
    const [searchTerm, setSearchTerm] = useState("");

    const filteredCertifications = certifications.filter((cert) =>
        cert.artist_name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="certification-list">
            {/* Barre de recherche */}
            <Input
                type="text"
                placeholder="Rechercher un artiste..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="custom-input mb-3"
            />

            {/* Tableau des certifications */}
            <Table hover responsive className="custom-table">
                <thead>
                <tr>
                    <th>Nom d'artiste</th>
                    <th className="text-end">Dernière modification</th>
                    <th className="text-end"/>
                    <th className="text-end"/>
                </tr>
                </thead>
                <tbody>
                {filteredCertifications.map((certification) => (
                    <tr
                        key={certification.id}
                        className={`table-row ${certification.id === selectedCertification?.id ? "selected-row" : ""}`}
                        onClick={() => onSelectCertification(certification)}
                    >
                        <td>{certification.artist_name}</td>
                        <td className="text-end">
                            {dayjs(certification.modified_at).format("DD/MM/YYYY HH:mm")}
                        </td>
                        <td className="text-end">

                            <VerifiedIcon
                                fontSize="small"
                                sx={{
                                    color: certification?.status === 'pending'
                                        ? '#cfd8dc !important'
                                        : certification?.status === 'accepted'
                                            ? '#1976d2 !important'
                                            : '#ffccbc !important',
                                    marginBottom: 0.5
                                }}/>
                        </td>
                        <td className="text-end">
                            <EditIcon
                                fontSize="small"
                                sx={{marginBottom: 0.5}}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    tOpenModal(certification);
                                }}
                            />
                        </td>
                    </tr>
                ))}
                </tbody>
            </Table>
        </div>
    );
}

export default CertificationList;
