import Typography from "@mui/material/Typography";
import React, {useState} from "react";
import {Nav, NavItem, NavLink, TabContent, TabPane, Modal, ModalBody} from "reactstrap";
import classnames from "classnames";
import {detectFileType} from "../../tools/tools";

function CertificationDetail({certification}) {
    const [activeTab, setActiveTab] = useState("1");
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    const getStatusText = (status) => {
        switch (status) {
            case 'pending':
                return 'En attente';
            case 'in_verification':
                return 'En cours de vérification';
            case 'accepted':
                return 'Compte certifié';
            case 'declined':
                return 'Demande déclinée';
            default:
                return 'Statut inconnu';
        }
    };

    const toggleTab = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };

    const openImageInModal = (url) => {
        setSelectedImage(url);
        toggleModal();
    };

    return (
        <div className="certification-detail">
            <p>
                <strong>Artiste :</strong> {certification.artist_name}
            </p>
            <p>
                <strong>Status :</strong> {getStatusText(certification.status)}
            </p>
            <div className="certification-tabs">
                <Nav tabs className="mb-3">
                    <NavItem>
                        <NavLink
                            className={classnames({active: activeTab === "1"})}
                            onClick={() => toggleTab("1")}
                        >
                            Documents
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({active: activeTab === "2"})}
                            onClick={() => toggleTab("2")}
                        >
                            Texte Extrait
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({active: activeTab === "3"})}
                            onClick={() => toggleTab("3")}
                        >
                            Message Admin
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="1" className="documents-tab">
                        <div className="documents-grid">
                            {certification.document_urls.map((url, index) => {
                                const fileType = detectFileType(url);

                                return fileType === "image" ? (
                                    <img
                                        key={index}
                                        src={url}
                                        alt={`Document ${index + 1}`}
                                        className="document-preview img-fluid mb-3"
                                        onClick={() => openImageInModal(url)}
                                    />
                                ) : (
                                    <Typography
                                        variant="body2"
                                        onClick={() => openImageInModal(url)}
                                        sx={{color: "#afa9a9", cursor: "pointer !important"}}
                                    >
                                        Appuyer pour voir le document pdf
                                    </Typography>
                                )
                            })}
                        </div>
                    </TabPane>
                    <TabPane tabId="2" className="text-justify">
                        <p style={{whiteSpace: 'pre-wrap'}}>
                            {certification.text_extracted || "Aucun texte extrait."}
                        </p>
                    </TabPane>
                    <TabPane tabId="3" className="text-justify">
                        <p>{certification.admin_message || "Aucun message."}</p>
                    </TabPane>
                </TabContent>
            </div>


            {/* Modal pour afficher les images en plein écran */}
            <Modal isOpen={modalOpen} toggle={toggleModal} size="lg" centered>
                <ModalBody>
                    {selectedImage && (
                        detectFileType(selectedImage) === "image" ? (
                            <img src={selectedImage} alt="Agrandissement" className="img-fluid"/>
                        ) : (
                            <iframe
                                src={selectedImage}
                                title="Aperçu du document"
                                style={{width: "100%", height: "500px", border: "none"}}
                            />
                        )
                    )}
                </ModalBody>
            </Modal>
        </div>
    );
}

export default CertificationDetail;
