import axios from "axios";
import React, {useCallback, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Col, Row, Spinner} from "reactstrap";
import ContactCard from "../../components/Main/contactCards/contactCard";
import {currencyByAddress} from "../../tools/tools";
import ContactCardList from "./contactCardList";

function ContactCards() {

    const [loading, setLoading] = useState(true);
    const [currency, setCurrency] = useState('');
    const countries = useSelector(state => state.global.countries);
    const [contactCards, setContactCards] = useState([]);
    const [contactCardToShow, setContactCardToShow] = useState({});

    const checkLastContactCard = useCallback(async () => {
        setLoading(true)
        await axios.get('/admin/contact_cards/per_page/1').then((response) => {
            let data = response.data.data;
            if (data.length)
                toShowContact(data[0])
            else setContactCardToShow({})
            setContactCards(data)
            setLoading(false)
        })
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [])

    const toShowContact = (card) => {
        setCurrency(currencyByAddress(card?.reservation?.address, countries))
        setContactCardToShow(card)
    }

    useEffect(() => {
        checkLastContactCard().then(() => null);
    }, [checkLastContactCard]);

    useEffect(() => {

    }, []);

    return (
        <div className="content">
            {loading ? (
                <div className="text-center mt-5">
                    <Spinner color="primary"/>
                    <p>Chargement des prises de contact...</p>
                </div>
            ) : (
                <Row>
                    <Col md="4">
                        <ContactCardList
                            contactCards={contactCards}
                            toShowContact={toShowContact}
                            setContactCards={setContactCards}
                            contactCardToShow={contactCardToShow}
                            setContactCardToShow={setContactCardToShow}
                            checkLastContactCard={checkLastContactCard}/>
                    </Col>
                    <Col md="8">
                        {Object.keys(contactCardToShow).length !== 0
                            ? <ContactCard contactCard={contactCardToShow} currency={currency}/>
                            : <p className="text-center">Pas de contact a afficher</p>
                        }
                    </Col>
                </Row>
            )}
        </div>
    );
}

export default ContactCards;
