import axios from "axios";
import React, {useCallback, useEffect, useState} from "react";
import {Card, CardBody, CardFooter, Col, Row, Spinner} from "reactstrap";

function UserStats() {
    const [dj, setDj] = useState(0);
    const [musician, setMusician] = useState(0);
    const [loading, setLoading] = useState(false);
    const [auditorPRO, setAuditorPRO] = useState(0);

    const checkUserStat = useCallback(async () => {
        setLoading(true);
        try {
            const response = await axios.get('/admin/users/stats');
            const data = response.data;
            setDj(data['dj_stat']);
            setMusician(data['musician_stat']);
            setAuditorPRO(data['auditor_pro_stat']);
        } catch (error) {
            console.error("Failed to fetch user stats:", error);
        } finally {
            setLoading(false);
        }
    }, []);

    const total = () => auditorPRO + dj + musician;

    useEffect(() => {
        checkUserStat().then(r => null);
    }, [checkUserStat]);

    return (
        <Card className="user-stats-card">
            <CardBody>
                <p className="text-center text-uppercase title">Nombre d'utilisateurs</p>
                <hr className="custom-divider"/>
                <Row>
                    <Col md="6" xs="5">
                        <div className="stat-row">
                            <em className="stat-label">Dj</em>
                            <p className="stat-value">{dj}</p>
                        </div>
                        <div className="stat-row">
                            <em className="stat-label">Auditor Pro</em>
                            <p className="stat-value">{auditorPRO}</p>
                        </div>
                        <div className="stat-row">
                            <em className="stat-label">Musicien</em>
                            <p className="stat-value">{musician}</p>
                        </div>
                    </Col>
                    <Col md="6" xs="7" className="total-section">
                        <div>
                            <p className="card-category">Total</p>
                            <h5 className="total-value">{total()}</h5>
                        </div>
                    </Col>
                </Row>
            </CardBody>
            <CardFooter className="footer">
                <div className="refresh">
                    {loading ? (
                        <Spinner size="sm" color="light"/>
                    ) : (
                        <button
                            className="btn-refresh"
                            onClick={checkUserStat}
                            title="Actualiser les statistiques"
                        >
                            <i className="fas fa-sync-alt"></i>
                        </button>
                    )}
                </div>
            </CardFooter>

        </Card>
    );
}

export default UserStats;
