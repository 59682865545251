import Dashboard from "views/Dashboard.js";
import UserPage from "./views/usersManage/User.js";
import Services from "./views/servicesManage/services";
import ContactCards from "./views/contactCardsManage/contactCards";
import PaymentHistory from "./views/paymentsManage/paymentHistory";
import AuditorProAndArtistes from "./views/usersManage/auditorProAndArtistes";
import ChatBot from "views/chatBot";
import Certifications from "./views/certificationsManage/certifications";

let routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "nc-icon nc-bank",
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/profile",
    name: "Admin Profil",
    icon: "nc-icon nc-single-02",
    component: UserPage,
    layout: "/admin",
  },
  {
    path: "/services",
    name: "Les Fiches KantoBiz's",
    icon: "nc-icon nc-globe",
    component: Services,
    layout: "/admin",
  },
  {
    path: "/contact_cards",
    name: "Prises de contact",
    icon: "nc-icon nc-book-bookmark",
    component: ContactCards,
    layout: "/admin",
  },
  {
    path: "/history_of_payments",
    name: "Payments",
    icon: "nc-icon nc-money-coins",
    component: PaymentHistory,
    layout: "/admin",
  },
  {
    path: "/auditor_pro_and_artists",
    name: "Utilisateurs",
    icon: "nc-icon nc-palette",
    component: AuditorProAndArtistes,
    layout: "/admin",
  },
  {
    path: "/certifications",
    name: "Certifications",
    icon: "nc-icon nc-check-2",
    component: Certifications,
    layout: "/admin",
  },
  {
    path: "/chatbot",
    name: "ChatBot & Serveur",
    icon: "nc-icon nc-bold",
    component: ChatBot,
    layout: "/admin",
  }
];
export default routes;
