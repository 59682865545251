import React, {useState} from "react";
import axios from "axios";
import {useSelector} from "react-redux";
import {Input, Table} from "reactstrap";

function ServiceList({services, serviceToShow, setServiceToShow, setReservations, checkLastServices}) {

    const countries = useSelector(state => state.global.countries);
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");

    const searchReservation = async (e) => {
        let str = e.target.value;
        setSearchTerm(str)
        if (str) {
            setLoading(true);
            await axios.get('/admin/services/search/' + str).then((response) => {
                let data = response.data;
                if (data.length)
                    setServiceToShow(data[0]);
                else setServiceToShow({});
                setReservations(data);
                setLoading(false);
            });
        } else {
            await checkLastServices()
        }
    };

    const filteredServices = services.filter((service) =>
        service.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        service.country.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="services-list">
            {/* Barre de recherche */}
            <Input
                type="text"
                value={searchTerm}
                placeholder="Rechercher un service, artiste, email, ..."
                onChange={(e) => searchReservation(e)}
                className="custom-input mb-3"
            />

            {/* Tableau des services */}
            <Table hover responsive className="custom-table">
                <thead>
                <tr>
                    <th>Titre</th>
                    <th>Pays</th>
                    <th className="text-end">Prix</th>
                </tr>
                </thead>
                <tbody>
                {filteredServices.length === 0
                    ? <tr>
                        <td colSpan="4" className="text-center">Aucun service trouvé</td>
                    </tr>
                    : filteredServices.map((service, index) => (
                        <tr
                            key={index}
                            className={`table-row ${serviceToShow?.id === service.id ? "selected-row" : ""}`}
                            onClick={() => setServiceToShow(service)}
                        >
                            <td>{service.title}</td>
                            <td>{service.country}</td>
                            <td className="text-end">
                                {service.price}&nbsp;
                                {countries?.find(country => country.name === service.country)?.currencies?.symbol}
                            </td>
                        </tr>
                    ))
                }
                </tbody>
            </Table>
        </div>
    );
}

export default ServiceList;
