import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from '@stripe/stripe-js';
import "assets/demo/demo.css";
import "assets/scss/paper-dashboard.scss?v=1.3.0";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import React from "react";
import ReactDOM from "react-dom";
import {Provider} from 'react-redux';
import {Switch} from "react-router";
import {BrowserRouter} from "react-router-dom";
import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import thunkMiddleware from "redux-thunk";
import App from "./App";
import config from "./config/tsconfig.json";
import Global from "./reducer/global";
import DecryptData from "./tools/DecryptData";

let stripePromise;
axios.defaults.headers.common['admin'] = "true"
axios.defaults.headers.common['Language'] = "fr"
axios.defaults.headers.common['Access-Control-Allow-Origin'] = "*"
axios.defaults.headers.common['Content-Type'] = 'application/json'
axios.defaults.headers.common['Isl-Token'] = JSON.parse(localStorage.getItem("creativeAdminToken"))
axios.defaults.baseURL = !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
    ? config.configs.ServerApiDev
    : config.configs.ServerApi
axios.interceptors.response.use(
    response => {
        return {data: DecryptData(response.data)};
    },
    error => {
        return Promise.reject(error);
    }
);

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    stripePromise = loadStripe('pk_test_Wg94pwR4mTzxLSdh5CL8JMRb');
} else {
    stripePromise = loadStripe('pk_live_1tS9tArJuSRec1e6WY3lon9C');
}

const Reducers = combineReducers({
    "global": Global,
});

const store = createStore(Reducers, undefined, compose(applyMiddleware(thunkMiddleware)));

ReactDOM.render(
    <Provider store={store}>
        <Elements stripe={stripePromise}>
            <BrowserRouter>
                <Switch>
                    <App/>
                </Switch>
            </BrowserRouter>
        </Elements>
    </Provider>,
    document.getElementById("root")
);
