import React, {useState} from "react";
import {useSelector} from "react-redux";
import {Input, Table} from "reactstrap";
import axios from "axios";


function ContactCardList({
                             contactCards,
                             contactCardToShow,
                             toShowContact,
                             setContactCardToShow,
                             setContactCards,
                             checkLastContactCard
                         }) {

    const status = useSelector((state) => state.global.status);
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");

    const searchContactCard = async (e) => {
        let str = e.target.value;
        setSearchTerm(str);
        if (str) {
            setLoading(true);
            await axios.get("/admin/contact_cards/search/" + str).then((response) => {
                let data = response.data["contact_cards"];
                if (data.length) toShowContact(data[0]);
                else setContactCardToShow({});
                setContactCards(data);
                setLoading(false);
            });
        } else {
            await checkLastContactCard()
        }
    };

    const filteredContactCards = contactCards.filter((card) =>
        card.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        card.reservation.artist_name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="contact-card-list">
            {/* Barre de recherche */}
            <Input
                type="text"
                value={searchTerm}
                placeholder="Rechercher la prise de contact, réservation, artiste, ..."
                onChange={(e) => searchContactCard(e)}
                className="custom-input mb-3"
            />

            {/* Tableau des contacts */}
            <Table hover responsive className="custom-table">
                <thead>
                <tr>
                    <th>Auditeur</th>
                    <th>Artiste</th>
                    <th>Status</th>
                </tr>
                </thead>
                <tbody>
                {filteredContactCards.length === 0 ? (
                    <tr>
                        <td colSpan="4" className="text-center">
                            Pas de prise de contact à afficher
                        </td>
                    </tr>
                ) : (
                    filteredContactCards.map((card, index) => (
                        <tr
                            key={index}
                            className={`table-row ${contactCardToShow?.id === card.id ? "selected-row" : ""}`}
                            onClick={() => setContactCardToShow(card)}
                        >
                            <td>{card.name}</td>
                            <td>{card.reservation.artist_name}</td>
                            <td>{status[card.status]}</td>
                        </tr>
                    ))
                )}
                </tbody>
            </Table>
        </div>
    );
}

export default ContactCardList;
