import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { Card, CardBody, CardFooter, Col, Row, Spinner } from "reactstrap";

function ContactCardOfTheMonth() {
    const [pending, setPending] = useState(0);
    const [declined, setDeclined] = useState(0);
    const [accepted, setAccepted] = useState(0);
    const [potentialFee, setPotentialFee] = useState(0);
    const [loading, setLoading] = useState(false);

    const checkContactStats = useCallback(async () => {
        setLoading(true);
        try {
            const response = await axios.get('/admin/contact_cards/income_month');
            const data = response.data;
            setAccepted(data['contact_card_accepted']);
            setPending(data['contact_card_pending']);
            setDeclined(data['contact_card_declined']);
            setPotentialFee(data['potential_fee']);
        } catch (error) {
            console.error("Failed to fetch contact stats:", error);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        checkContactStats().then(r => null);
    }, [checkContactStats]);

    return (
        <Card className="user-stats-card">
            <CardBody>
                <p className="text-center text-uppercase title">Prise de contact du mois</p>
                <hr className="custom-divider"/>
                <Row>
                    <Col md="6" xs="5">
                        <div className="stat-row">
                            <em className="stat-label">Accepté</em>
                            <p className="stat-value text-success">{accepted}</p>
                        </div>
                        <div className="stat-row">
                            <em className="stat-label">En attente</em>
                            <p className="stat-value text-warning">{pending}</p>
                        </div>
                        <div className="stat-row">
                            <em className="stat-label">Décliné</em>
                            <p className="stat-value text-danger">{declined}</p>
                        </div>
                    </Col>
                    <Col md="6" xs="7" className="total-section">
                        <div>
                            <p className="card-category">Potentiel</p>
                            <h5 className="total-value">{potentialFee || 0}€</h5>
                        </div>
                    </Col>
                </Row>
            </CardBody>
            <CardFooter className="footer">
                <div className="refresh">
                    {loading ? (
                        <Spinner size="sm" color="light"/>
                    ) : (
                        <button
                            className="btn-refresh"
                            onClick={checkContactStats}
                            title="Actualiser les statistiques"
                        >
                            <i className="fas fa-sync-alt"></i>
                        </button>
                    )}
                </div>
            </CardFooter>
        </Card>
    );
}

export default ContactCardOfTheMonth;
