import axios from "axios";
import React, {useCallback, useEffect, useRef, useState} from "react";
import NotificationAlert from "react-notification-alert";
import {Col, Row, Spinner} from "reactstrap";
import Service from "../../components/Main/services/service";
import ServiceList from "./serviceList";

function Services() {

    const notificationAlert = useRef()
    const [loading, setLoading] = useState(false);
    const [services, setReservations] = useState([]);
    const [serviceToShow, setServiceToShow] = useState({});

    const checkLastServices = useCallback(async () => {
        setLoading(true)
        await axios.get('/admin/services/last').then((response) => {
            let data = response.data;
            if (data.length)
                setServiceToShow(data[0])
            else setServiceToShow({})
            setReservations(data)
            setLoading(false)
        })
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [])

    useEffect(() => {
        checkLastServices().then(() => null);
    }, [checkLastServices]);

    useEffect(() => {

    }, []);

    return (
        <div className="content">
            <NotificationAlert ref={notificationAlert}/>
            {loading ? (
                <div className="text-center mt-5">
                    <Spinner color="primary"/>
                    <p>Chargement des services...</p>
                </div>
            ) : (
                <Row>
                    <Col md="4">
                        <ServiceList
                            services={services}
                            serviceToShow={serviceToShow}
                            setReservations={setReservations}
                            setServiceToShow={setServiceToShow}
                            checkLastServices={checkLastServices}/>
                    </Col>
                    <Col md="8">
                        <Service service={serviceToShow} checkLastServices={checkLastServices}/>
                    </Col>
                </Row>)}
        </div>
    );
}

export default Services;
